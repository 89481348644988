import styled, { css } from 'styled-components'

const Button = styled.button`
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
  user-select: none;
  box-shadow: ${({ elevation, theme }) => theme.boxShadows[elevation] || 'none'};
  border-radius: ${({ borderRadius, theme }) => borderRadius || theme.borderRadius.button};
  ${({ disabled, isLoading }) => disabled && !isLoading && 'opacity: 0.4;'}
  padding: ${({ padding }) => padding || '10px'};
  margin: ${({ margin }) => margin || '5px 0px'};
  text-align: center;
  border: none;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor] || theme.colors.lightGray};
  ${({ backgroundGradient, theme }) => backgroundGradient && css`background: ${theme.gradients[backgroundGradient] || theme.colors.lightGray};`}
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  display: flex;
  ${({ flex }) => flex && `flex: ${flex};`}
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent}) => justifyContent || 'center'};
  font-size: ${({ fontSize, theme }) => theme.fontSizes[fontSize] || theme.fontSizes.medium };
  color: ${({ color, theme}) => theme.colors[color] || theme.colors.black};
  transition: opacity 0.1s linear;
  opacity: 1;
  ${({ disabled }) => disabled && 'opacity: 0.4; cursor: not-allowed;'}

  &:active {
    opacity: 0.6;
  }
`

export default Button
