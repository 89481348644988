const theme = {
  colors: {
    primary: '#59CBE4',
    secondary: '#8177EB',
    success: '#1bd273',
    info: '#709cf0',
    warning: '#f0c570',
    error: '#e48383',
    background: '#f5f5f5',
    black: '#000000',
    darkGray: '#303030',
    gray: '#777777',
    metalic: '#919098',
    lightGray: '#e0e0e0',
    whitesmoke: '#f1f1f1',
    white: 'rgb(255, 255, 255)',
    blue: 'rgb(0,122,255)',
    orange: 'rgb(255,149,0)',
    red: 'rgb(255,59,48)',
    yellow: 'rgb(255,204,0)',
    green: 'rgb(76,217,100)',
    purple: 'rgb(88,86,214)',
    teal: 'rgb(90,200,250)',
    pink: 'rgb(255,45,85)',
  },
  gradients: {
    main: 'linear-gradient(270deg, #8177EB 0%, #59CBE4 100%)'
  },
  borderRadius: {
    card: '16px',
    input: '8px',
    component: '8px',
    button: '4px'
  },
  iconSizes: {
    small: '30px',
    medium: '40px',
    large: '50px'
  },
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    medium: '16px',
    large: '20px',
    extraLarge: '24px'
  },
  boxShadows: {
    low: '0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132)',
    medium: '0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132)',
    high: '0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132)',
    veryHigh: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
  }
}
export default theme;