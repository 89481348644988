import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import * as piece from "../../components/connect/piece";
// import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { PieceContext } from "../../stores/PieceStore";
const PieceList = () => {
  document.title = "WAAM App";
  const [pieces, setPieces] = useState([]);
  const [piecesBackup, setBackupPieces] = useState([]);
  const [gotSavedData, setGotSavedData] = useState(false);
  const [schearchText, setSchearchText] = useState("");
  const history = useHistory();
  const { setPieceStore, setCostumerDetail } = useContext(PieceContext);
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = useState("Pending");

  const handleSchearchTerm = () => {
    const SchearchTerm = document.getElementById("scearch").value;
    if (SchearchTerm !== "") {
      var filteredList = [];

      pieces.forEach((piece) => {
        if (piece.RequestID.toLowerCase().includes(SchearchTerm)) {
          filteredList.push(piece);
        }
      });
      setPieces(filteredList);
      setSchearchText(SchearchTerm);
    } else {
      setPieces(piecesBackup);
      setSchearchText(SchearchTerm);
    }
  };

  const handlePiece = (element) => {
    setPieceStore(element);
    history.push("/Piece");
  };

  const ConvertTimezone = (oldDate) => {
    var date = new Date(oldDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hour = ("0" + (date.getHours() + 1)).slice(-2),
      minute = ("0" + (date.getMinutes() + 1)).slice(-2);

    var currentDate = new Date();

    if (date.toDateString() === currentDate.toDateString()) {
      return hour + ":" + minute;
    }
    return [day, mnth, date.getFullYear()].join("/");
  };

  const handleGetAllByStatus = async () => {
    if (statusFilter === "Pending" || statusFilter === "In Progress" || statusFilter === "In progress" || statusFilter === "InProgress") {
      setStatusFilter("Achieved");

      var filtered = piecesBackup;
      var filteredList = [];

      filtered.forEach((piece) => {
        if (piece.RequestStatus === "Achieved") {
          filteredList.push(piece);
        }
      });
      setPieces(filteredList);
    } else if (statusFilter === "Achieved" || statusFilter === "Completed") {
      setStatusFilter("Pending");
      var newfiltered = piecesBackup;
      var newfilteredList = [];

      newfiltered.forEach((piece) => {
        if (piece.RequestStatus === "Pending") {
          newfilteredList.push(piece);
        }
      });
      setPieces(newfilteredList);
    }
  };


  const WhiteUnitBoxDiv = styled.div`
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 10px 12px 5px 12px;
  margin-left: 10px;
  cursor: pointer;
  
  .active {
    color: #009ee0;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7abcf1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -2.5px;
    background-color: #2196f3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #7abcf1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #7abcf1;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 85%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }

    select {
      -webkit-appearance: none;
      padding: 7px 40px 7px 12px;
      width: 200px;
      margin-left: 10px;
      border: 0px;
      border-radius: 5px;
      background: white;
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      transition: all 150ms ease;
      :focus {
        outline: none;
      }
    }

    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 100%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 7%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 7%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
      width: 4%;
      justify-content: center;
      align-items: center;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
    }
    img {
      margin-right: 10px;
    }
    span {
      background-color: #8d1677;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 16px;
    }
  `;
  var costumer = []

  const ListPieces = () => {
    if (gotSavedData === false) {
      const today = new Date();
      var start = new Date();

      start.setDate(1);
      start.setFullYear(1995)

      setTimeout(async () => {
        var result = await piece.ListAllPieces(start, today);
        result.sort((a, b) => (a.CreationDate > b.CreationDate ? -1 : 1));
        if (result !== undefined && result !== []) {
          for (let index = 0; index < result.length; index++) {
            let element = result[index].BuyerID;
            costumer.push(element)
          }
          setBackupPieces(result);
        }
        setGotSavedData(true);
        var detail = await piece.GetCustomerDetail({ filter: { ids: costumer } });
        result = result.map((r) => {
          var index = detail.findIndex((d) => d.id === r.BuyerID)
          if(index !== -1){
            return {...r, name: detail[index].firstName + " " + detail[index].lastName, company: detail[index].company }
          } else {
            return r
          }
        })
        setCostumerDetail(detail)
        setPieces(result);
      }, 50);
    }
  };

  useEffect(() => {
    setGotSavedData(false)
    ListPieces()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter])


  return (
    <Body>
      {ListPieces()}
      <Header />
      <NavBar />
      {!gotSavedData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold> {t("Messages.Messages")} </HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder={t("Messages.Scearch")}
              onChange={handleSchearchTerm}
              defaultValue={schearchText}
              autoFocus
            ></input>
            <WhiteUnitBoxDiv>
              <div
                id="Pending"
                onClick={() => handleGetAllByStatus()}
                className={statusFilter === "Pending" || statusFilter === "In Progress" ||
                  statusFilter === "InProgress" || statusFilter === "In progress"
                  ? "active" : ""
                }
              >
                {t("Messages.Pending")}
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  onClick={() => handleGetAllByStatus()}
                  id={"Pending"}
                  value="Pending"
                  defaultChecked={statusFilter === "Pending" || statusFilter === "In Progress" ||
                    statusFilter === "InProgress" || statusFilter === "In progress" ? true : false}
                />
                <span className="slider round"></span>
              </label>
              <div
                id="Achieved"
                onClick={() => handleGetAllByStatus()}
                className={statusFilter === "Achieved" || statusFilter === "Completed" ? "active" : ""}
              >
                {t("Messages.Achieved")}
              </div>
            </WhiteUnitBoxDiv>
          </SideBySideOptions>
          {pieces.map((Pieces) => (
            Pieces.RequestStatus === statusFilter ?
                <div key={Pieces.RequestID}>
                  <SavedDiv>
                    <button onClick={() => handlePiece(Pieces)}>
                      <div
                        className={
                          Pieces.RequestStatus === "Pending" || Pieces.RequestStatus === "In Progress" ||
                            Pieces.RequestStatus === "InProgress" || Pieces.RequestStatus === "In progress" ?
                            "statusPending" : "statusProcessed"
                        }
                      >
                        {t("Messages." + Pieces.RequestStatus)}
                      </div>
                      <div className="information">
                        <div className="left">
                          <img src={MessageIcon} alt="Message Icon" />
                          <div>
                            <font>
                              {t("Messages.RequestFrom")} {Pieces.name + " - " + Pieces.company}
                            </font>
                            <div className="GrayText">
                              {t("Messages.Format")}{" "}
                              {Pieces.GeometryInfo.GeometryType} <div />
                              {t("Messages.Material") +
                                " " +
                                Pieces.MaterialInfo.MaterialType}
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          {ConvertTimezone(Pieces.CreationDate)}
                        </div>
                      </div>
                    </button>
              
                  </SavedDiv>
                </div>
             : null
          ))}
        </MainCard>
      )}
    </Body>
  );
};

export default PieceList;
