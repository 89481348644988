import i18n from 'i18next'
import 'moment/locale/pt-br'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en.json'
import ptBR from './pt-br.json'
import es from './es.json'
import fr from './fr.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  'pt-BR': {
	  translation: ptBR
  },
  'es': {
	  translation: es
  },
  'fr': {
	  translation: fr
  }
}

// passes i18n down to react-i18next
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: ['en', 'pt-BR'],
  whitelist: ['pt-BR', 'en', 'es','fr'],
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  detection: {
    order: ['cookie', 'navigator', 'localstorage'],
    lookupCookie: 'language',
    lookupLocalStorage: 'language',
    domain: process.env.REACT_APP_DOMAIN
  }
})

export default i18n