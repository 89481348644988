import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Body, MainCard } from "../../components/common/MainCard";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import CountryCodeSelect from "../../containers/common/CountryCodeSelect";
import LanguageSelect from "../../containers/common/LanguageSelect";
import { UserContext } from "../../stores/UserStore";
import { GetProfileImage, UpdateUser } from "../../components/connect/profile";
import { CgProfile } from "react-icons/cg";

const Profile = () => {
  document.title = "Profile";
  const { currentUser } = useContext(UserContext);
  const history = useHistory();

  const { t } = useTranslation();
  const [mesuringUnit, setMesuringUnit] = useState("Metric")

  const handleChangeMetricUnit = () => {
    if (mesuringUnit === "Metric") {
      document.getElementById("Metric").className = "";
      document.getElementById("Imperial").className = "active";
      setMesuringUnit("Imperial");
    } else {
      document.getElementById("Metric").className = "active";
      document.getElementById("Imperial").className = "";
      setMesuringUnit("Metric")
    }
  };

  const ImageDiv = styled.div`
    color: black;
    display: flex;
    align-items: center;
    margin-left: 30px;
    font {
      color: #009ee0;
    }
    div {
      font-size: calc(12px + 0.5vw);
      color: black;
      padding-left: 16px;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      height: 50px;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
  `;

  const SideBySideOptions = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    div {
      flex: 1;
    }
  `;
  const SideBySideOptionsPhoneDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .Left {
      flex: 1;
      margin-left: 20px;
      margin-right: 20px;
    }
    .Right {
      margin-right: 30px;
      flex: 1;
    }
  `;

  const ProfileForm = styled.div`
    font-size: calc(8px + 0.5vw);
    display: flex;
    flex-direction: column;
    padding: 2vh;
    margin-top: 10px;
    width: 100%;
    color: #7e7e7e;
    border-radius: 8px;
    background-color: white;
    transform: translateX(-30px);
    justify-items: center;
    align-items: center;
    position: relative;

    .smallerScreen {
      bottom: 25px;
    }

    input[type="text"],
    select {
      width: 95%;
      padding: 1.2vh 20px;
      margin: 2.4vh 24px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  `;

  const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  const CenterDiv = styled.div`
    color: black;
    display: flex;
    width: 100%;
    max-width: 50vw;
    align-items: center;
    padding-bottom: 1vh;

    .Company {
      margin-top: 100px;
    }
   
  `;
  const MyInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 50vw;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
  `;

  const MyLanguageDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 50vw;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2vh;
    margin-bottom: 2vh;
    background-color: #f8f9fa;
    font-size: 14px;
    font-weight: bold;
    font {
      padding-bottom: 1vh;
    }
    .Field {
      display: flex;
      justify-content: start;
      flex-direction: column;
      padding: 2vh;
      flex: 1;
    }
  `;

  const WhiteUnitBoxDiv = styled.div`
    display: flex;
    background-color: white;
    justify-content: center;
    padding-top: 10px;

    .active {
      color: #009ee0;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 15px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #7abcf1;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0;
      bottom: -2.5px;
      background-color: #2196f3;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #7abcf1;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #7abcf1;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  `;

  const SaveButtonDiv = styled.div`
    // display: flex;
    // width: 100%;
    // bottom: 85px;
    justify-content: center;
    margin: 4vh;

    button {
      margin-bottom: 50px;
      cursor: pointer;
      min-height: 20px;
      height: 5vh;
      max-height: 50px;
      border-radius: 10px;
      width: 10vw;
      min-width: 100px;
      background-color: #009ee0;
      color: white;
      font-size: calc(8px + 0.5vw);
      border: 0 solid #fff;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const getProfileImage = () => {
    let profilePicture = null
    GetProfileImage(currentUser.id).then((response) => {
      profilePicture = response
    })
    if (profilePicture) {
      const image = profilePicture;
      return (
        <ImageDiv>
          <img src={image} alt="Profile" />
        </ImageDiv>
      );
    } else {
      return (
        <ImageDiv>
          <CgProfile size="35px" style={{ marginRight: "30px", marginLeft: "-20px" }}/>
        </ImageDiv>
      );
    }
  }

  const checkForCurrentUserInfo = () => {
    if (currentUser == null) {
      toast.error("Error Getting User Info");
      history.push("/landingPage");
    }
  };

  const handleUpdateProfile = async () => {
    let firstName = document.getElementById("FirstName").value.toString()
    let lastName = document.getElementById("LastName").value.toString()
    let company = document.getElementById("CompanyName").value.toString()
    let address = document.getElementById("CompanyAddress").value.toString()
    let city = document.getElementById("City").value.toString()
    let position = document.getElementById("Position").value.toString()
    let country = document.getElementById("Country").value.toString()
    let phoneNumber = document.getElementById("Phone").value.toString()
    let zipCode = document.getElementById("ZipCode").value.toString()

    const data = {
      "update": {
        "id": currentUser.id,
        "measuringUnit": mesuringUnit,
        "firstName": firstName,
        "lastName": lastName,
        "company": company,
        "location": currentUser.location,
        "address": address,
        "zipCode": zipCode,
        "city": city,
        "position": position,
        "country": country,
        "phoneNumber": phoneNumber,
        "countryCode": currentUser.countryCode,
        "type": currentUser.type,
        "language": currentUser.language,
      }
    }
    await UpdateUser(data);
    history.push("/landingPage");
  }

  return (
    <Body>
      <Header />
      <NavBar />
      {checkForCurrentUserInfo()}
      <MainCard id="MainCard">
        {currentUser != null && currentUser != null && (
          <FlexDiv>
            <ProfileForm>
              <CenterDiv>
                {t("Profile.MyInformation")} <div />
              </CenterDiv>
              <MyInfoDiv>
                <SideBySideOptions>
                  <input
                    id="FirstName"
                    type="text"
                    defaultValue={currentUser.firstName}
                    placeholder={t("Profile.FirstName")}
                  ></input>
                  <input
                    id="LastName"
                    type="text"
                    defaultValue={currentUser.lastName}
                    placeholder={t("Profile.LastName")}
                  ></input>
                  {getProfileImage()}
                </SideBySideOptions>
                <input
                  id="Email"
                  type="text"
                  defaultValue={currentUser.email}
                  placeholder={t("Profile.Email")}
                  disabled
                ></input>
                <input
                  id="Position"
                  type="text"
                  defaultValue={currentUser.position}
                  placeholder={t("Profile.YourPosition")}
                ></input>
                <SideBySideOptionsPhoneDiv>
                  <div className="Left">
                    <CountryCodeSelect />
                  </div>
                  <div className="Right">
                    <input
                      id="Phone"
                      type="text"
                      defaultValue={currentUser.phoneNumber}
                      placeholder={t("Profile.Phone")}
                    ></input>
                  </div>
                </SideBySideOptionsPhoneDiv>
              </MyInfoDiv>
              <MyLanguageDiv>
                <div className="Field">
                  <font>{t("Profile.Language")}</font> <div />
                  <LanguageSelect />
                </div>
                <div className="Field">
                  <font> {t("Profile.Units")}</font> <div />
                  <WhiteUnitBoxDiv>
                    <div
                      id="Metric"
                      className={
                        currentUser !== null &&
                          currentUser.measuringUnit !== null &&
                          currentUser.measuringUnit === "Metric"
                          ? "active"
                          : ""
                      }
                    >
                      {t("Profile.METRIC")}
                    </div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={handleChangeMetricUnit}
                        defaultChecked={mesuringUnit === "Imperial" ? true : false}
                      />
                      <span className="slider round"></span>
                    </label>
                    <div
                      id="Imperial"
                      className={mesuringUnit === "Imperial" ? "active" : ""}
                    >
                      {t("Profile.IMPERIAL")}
                    </div>
                  </WhiteUnitBoxDiv>
                </div>
              </MyLanguageDiv>
              <CenterDiv id="Company">
                {t("Profile.CompanyInformation")} <div />
              </CenterDiv>
              <MyInfoDiv>
                <input
                  id="CompanyName"
                  type="text"
                  placeholder={t("Profile.CompanyName")}
                  defaultValue={currentUser.company}
                ></input>
                <input
                  id="CompanyAddress"
                  type="text"
                  placeholder={t("Profile.CompanyAddress")}
                  defaultValue={currentUser.address}

                ></input>
                <SideBySideOptions>
                  <input
                    id="ZipCode"
                    type="text"
                    placeholder={t("Profile.ZipCode")}
                    defaultValue={currentUser.zipCode}
                  ></input>
                  <input
                    id="City"
                    type="text"
                    defaultValue={currentUser.city}
                    placeholder={t("Profile.City")}
                  ></input>
                  <input
                    id="Country"
                    type="text"
                    placeholder={t("Profile.Country")}
                    defaultValue={currentUser.country}
                  ></input>
                </SideBySideOptions>
              </MyInfoDiv>
              <SaveButtonDiv>
                <button
                  onClick={() => handleUpdateProfile()}
                >
                  {t("Profile.Save")}
                </button>
              </SaveButtonDiv>
            </ProfileForm>
          </FlexDiv>
        )}
      </MainCard>
    </Body>
  );
};

export default Profile;
