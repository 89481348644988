
import React from "react";
import { toast, Zoom } from "react-toastify";
import { ThemeProvider } from "styled-components";

import "./i18n";
import Router from "./Router";
import { LandingPageProvider } from "./stores/LandingPageStore";
import { PieceProvider } from "./stores/PieceStore";
import { UserProvider } from "./stores/UserStore";
import theme from "./utils/theme";

toast.configure({
  autoClose: 4000,
  draggable: false,
  transition: Zoom,
  hideProgressBar: true,
  closeButton: false,
});


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <LandingPageProvider>
          <PieceProvider>
            <Router />
          </PieceProvider>
        </LandingPageProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
