import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius
      ? theme.borderRadius[borderRadius]
        ? theme.borderRadius[borderRadius]
        : borderRadius
      : "0px"};
`;

const LoadingOverlay = (props) => (
  <Wrapper {...props}>
    <Spinner />
  </Wrapper>
);

export default React.memo(LoadingOverlay);
