import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute  from "./containers/router/PrivateRoute"
import LandingPage from "./pages/LandingPage/LandingPage";
import ListPiece from "./pages/Pieces/PiecesList";
import Piece from "./pages/Pieces/Piece";
import RedirectTo from "./pages/RedirectTo";
import Profile from './pages/Profile/Profile'

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/redirect/:to" exact>
          <RedirectTo />
        </Route>
        <PrivateRoute path="/">
          <Switch>
            <PrivateRoute exact path='/'>
              <LandingPage />
            </PrivateRoute>
            <PrivateRoute exact path='/landingPage'>
              <LandingPage />
            </PrivateRoute>
            <PrivateRoute exact path='/ListPieces'>
              <ListPiece />
            </PrivateRoute>
            <PrivateRoute exact path='/piece'>
              <Piece />
            </PrivateRoute>
            <PrivateRoute exact path='/Profile'>
              <Profile />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Redirect to="/" />
            </PrivateRoute>
          </Switch>
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

// <Route path="/landingPage" component={LandingPage} />
// <Route path="/ListPieces" component={ListPiece} />
// <Route path="/piece" component={Piece} />