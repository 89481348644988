import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  cursor: pointer;

  &.modal-enter {
    opacity: 0;
  }
  &.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`

const ModalContent = ({ onOutsideClick, children }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = e => {
      onOutsideClick && wrapperRef && (wrapperRef.current === e.target) && onOutsideClick()
    }
    document.addEventListener("click", handleOutsideClick)

    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [onOutsideClick])

  const renderComponent = <Wrapper ref={wrapperRef}>{ children }</Wrapper>
  return ReactDOM.createPortal(renderComponent, document.body)
}

const Modal = ({ isOpen, onModalExit, ...rest}) => {
  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="modal"
      onExited={onModalExit}
      unmountOnExit
    >
      <ModalContent {...rest} />
    </CSSTransition>
  )
}

export default React.memo(Modal)