import React, { useState } from "react";

const initialState = {
    item: 1,
};

export const LandingPageContext = React.createContext(initialState);

export const LandingPageProvider = ({ children }) => {
    const [gotCustomerInformation, setGotCustomerInformation] = useState(false);
    const [submittedPiecesAmount, setSubmittedPiecesAmount] = useState("");
    const [frequentMaterialPieces, setFrequentMaterialPieces] = useState("");
    const [frequentTypePieces, setFrequentTypePieces] = useState("");
    const [amountPending, setAmountPending] = useState("");

    const CountPieces = (data) => {
        setSubmittedPiecesAmount(data.length);

        let amountPendingTemp = 0;
        let frequentMaterialPiecesTemp = [0, 0, 0];
        let frequentTypePiecesTemp = [0, 0, 0, 0, 0, 0, 0];

        for (let i = 0; i < data.length; i++) {
            if (data[i].RequestStatus === "Pending") {
                amountPendingTemp++;
            }

            if (data[i].MaterialInfo !== null || data[i].MaterialInfo !== undefined) {
                if (
                    data[i].MaterialInfo.MaterialType !== null ||
                    data[i].MaterialInfo.MaterialType !== undefined
                ) {
                    if (data[i].MaterialInfo.MaterialType === "CarbonSteel") {
                        frequentMaterialPiecesTemp[0] = frequentMaterialPiecesTemp[0] + 1;
                    } else if (data[i].MaterialInfo.MaterialType === "CRA") {
                        frequentMaterialPiecesTemp[1] = frequentMaterialPiecesTemp[1] + 1;
                    } else if (data[i].MaterialInfo.MaterialType === "NickelAlloy") {
                        frequentMaterialPiecesTemp[2] = frequentMaterialPiecesTemp[2] + 1;
                    }
                }

                if (data[i].MaterialInfo.MaterialName !== null) {
                    if (data[i].MaterialInfo.MaterialName === "X-over") {
                        frequentTypePiecesTemp[0] = frequentTypePiecesTemp[0] + 1;
                    } else if (data[i].MaterialInfo.MaterialName === "Closure") {
                        frequentTypePiecesTemp[1] = frequentTypePiecesTemp[1] + 1;
                    } else if (data[i].MaterialInfo.MaterialName === "Flange") {
                        frequentTypePiecesTemp[2] = frequentTypePiecesTemp[2] + 1;
                    } else if (data[i].MaterialInfo.MaterialName === "Gasket") {
                        frequentTypePiecesTemp[3] = frequentTypePiecesTemp[3] + 1;
                    } else if (data[i].MaterialInfo.MaterialName === "Tube") {
                        frequentTypePiecesTemp[4] = frequentTypePiecesTemp[4] + 1;
                    } else if (data[i].MaterialInfo.MaterialName === "Collar") {
                        frequentTypePiecesTemp[5] = frequentTypePiecesTemp[5] + 1;
                    } else if (data[i].MaterialInfo.MaterialName === "Other") {
                        frequentTypePiecesTemp[6] = frequentTypePiecesTemp[6] + 1;
                    }
                }
            }
        }

        setAmountPending(amountPendingTemp);

        var biggerMaterialType = 0;
        var selectedMaterialType = 3;
        for (let i = 0; i < frequentMaterialPiecesTemp.length; i++) {
            if (biggerMaterialType < frequentMaterialPiecesTemp[i]) {
                biggerMaterialType = frequentMaterialPiecesTemp[i];
                selectedMaterialType = i;
            }
        }

        switch (selectedMaterialType) {
            case 0:
                setFrequentMaterialPieces("CarbonSteel");
                break;
            case 1:
                setFrequentMaterialPieces("CRA");
                break;
            case 2:
                setFrequentMaterialPieces("NickelAlloy");
                break;
            case 3:
                setFrequentMaterialPieces("None");
                break;
            default:
                break;
        }

        var biggerMaterialFormat = 0;
        var selectedMaterialFormat = 7;
        for (let i = 0; i < frequentTypePiecesTemp.length; i++) {
            if (biggerMaterialFormat < frequentTypePiecesTemp[i]) {
                biggerMaterialFormat = frequentTypePiecesTemp[i];
                selectedMaterialFormat = i;
            }
        }

        switch (selectedMaterialFormat) {
            case 0:
                setFrequentTypePieces("X-over");
                break;
            case 1:
                setFrequentTypePieces("Closure");
                break;
            case 2:
                setFrequentTypePieces("Flange");
                break;
            case 3:
                setFrequentTypePieces("Gasket");
                break;
            case 4:
                setFrequentTypePieces("Tube");
                break;
            case 5:
                setFrequentTypePieces("Collar");
                break;
            case 6:
                setFrequentTypePieces("Other");
                break;
            case 7:
                setFrequentTypePieces("None");
                break;
            default:
                break;
        }
    };

    const renderLandingPage = (
        <LandingPageContext.Provider
            value={{
                gotCustomerInformation, setGotCustomerInformation,
                submittedPiecesAmount, setSubmittedPiecesAmount,
                frequentMaterialPieces, setFrequentMaterialPieces,
                frequentTypePieces, setFrequentTypePieces,
                amountPending, setAmountPending,
                CountPieces
            }}
        >
            {children}
        </LandingPageContext.Provider>
    );

    return renderLandingPage;
};
