import React from "react";
import { useTranslation } from "react-i18next";
import { GiTeePipe } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import OpenMenu from "../../assets/images/NavBar/OpenMenu.svg";
import Start from "../../assets/images/NavBar/Start.svg";

const NavBar = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const NavBarDiv = styled.div`
    font-family: Roboto;
    float: left;
    width: 40px;
    height: 100%;
    background: rgb(32, 32, 215);
    background: linear-gradient(180deg, #172983 0%, #00093f 100%);
    transition: all 0.2s ease 0s;

    font {
      display: none;
      font-size: 12px;
      margin-left: 10px;
    }
    .retractNavBar {
      display: none;
      flex-direction: flex-start;
      img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }

    .active {
      transition: all 0.3s ease 0s;
      background-color: white;
      text-decoration: none;
      img {
        filter: invert(10%) sepia(52%) saturate(3294%) hue-rotate(231deg)
          brightness(0%) contrast(96%);
      }
      color: black;
    }

    span {
      background-color: #8d1677;
      height: 10px;
      width: 10px;
      color: white;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 8px;
      transform: translate(-5px, -10px);
    }

    button {
      width: 100%;
      display: flex;
      flex-direction: flex-start;
      border: 0;
      color: #e7e6e6;
      margin-top: 5px;
      padding-top: 5px;
      background-color: Transparent;
      align-items: center;
      text-align: left;
      cursor: pointer;

      :hover {
        transition: all 0.3s ease 0s;
        background-color: white;
        text-decoration: none;
        color: black;
        img {
          filter: invert(10%) sepia(52%) saturate(3294%) hue-rotate(231deg)
            brightness(0%) contrast(96%);
        }
      }
    }
  `;

  const handleExpandNavBar = () => {
    document.getElementById("NavBar").style.width = "200px";
    document.getElementById("expandNavBar").style.display = "none";
    document.getElementById("retractNavBar").style.display = "flex";
    document.getElementById("MainCard").style.marginLeft = "200px";
    document.getElementById("MainCard").style.width = "90vw";
    const showTexts = document.getElementsByClassName("HiddenText");

    for (let i = 0; i < showTexts.length; i++) {
      showTexts[i].style.display = "block";
    }
  };

  const handleRetractNavBar = () => {
    document.getElementById("NavBar").style.width = "40px";
    document.getElementById("expandNavBar").style.display = "block";
    document.getElementById("retractNavBar").style.display = "none";
    document.getElementById("MainCard").style.marginLeft = "40px";
    document.getElementById("MainCard").style.width = "98vw";
    const showTexts = document.getElementsByClassName("HiddenText");

    for (let i = 0; i < showTexts.length; i++) {
      showTexts[i].style.display = "none";
    }
  };

  const ActivateElement = (elementName) => {
    var element = document.getElementById(elementName);
    if (element != null) {
      element.className = "active";
    }
  };

  const handleGetCurrentLocation = () => {
    setTimeout(() => {
      if (window.location.href.includes("LandingPage")) {
        ActivateElement("Start");
      } else if (window.location.href.includes("Pieces")|| window.location.href.includes("Piece")) {
        ActivateElement("Pieces");
      }
    }, 1000);
  };

  const handleLandingPage = () => {
    history.push("/LandingPage");
  };

  const handleListPieces = () => {
    history.push("/ListPieces");
  };

  return (
    <NavBarDiv id="NavBar">
      <button
        id="expandNavBar"
        className="expandNavBar"
        onClick={handleExpandNavBar}
      >
        <img alt="" src={OpenMenu} />
      </button>
      <button
        id="retractNavBar"
        className="retractNavBar"
        onClick={handleRetractNavBar}
      >
        <img alt="" src={OpenMenu} />
      </button>

      <button
        onClick={handleLandingPage}
        id="Start"
        style={{ paddingLeft: "12px" }}
      >
        <img alt="" src={Start} />
        <font className="HiddenText"> {t("NavBar.Start")} </font>
      </button>
      <button onClick={handleListPieces} id="Pieces">
        <GiTeePipe size="25px" />
        <font className="HiddenText">{t("NavBar.Requests")}</font>
      </button>
      {handleGetCurrentLocation()}
    </NavBarDiv>
  );
};

export default NavBar;
