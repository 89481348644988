import styled, { keyframes } from 'styled-components'
import SpinnerImage from '../../assets/icons/spinner.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
  background-image: url(${SpinnerImage});
  background-repeat: no-repeat;
  background-size: cover;
  width: ${({ width }) => width || '4rem'};
  height: ${({ height }) => height || '4rem'};
`

export default Spinner