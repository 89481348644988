import styled from 'styled-components'
import FlexView from './FlexView'

const Card = styled(FlexView)`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ elevation, theme }) => theme.boxShadows[elevation] || theme.boxShadows.low};
  border-radius: ${({ borderRadius, theme }) => borderRadius || theme.borderRadius.card};
  padding: ${({ padding }) => padding || '20px'};
`

export default Card