import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

const EndpointListTransactions = process.env.REACT_APP_API_URL + "/PieceRequest/List";

const EndpointUpdateTransactions =  process.env.REACT_APP_API_URL + "/PieceRequest/Update";

const EndpointGetCustomerDetail = process.env.REACT_APP_AUTH_URL +  '/application/users';

const header = {
  'vlr-authorization': 'eyJpZCI6IldBQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}
export async function ListAllPieces(InitialDate, FinalDate) {
 let data = { InitialDate: InitialDate, FinalDate: FinalDate };
  return await axios
    .post(EndpointListTransactions, data, { headers: header})
    .then((response) => {
      return response.data.Response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function UpdatePieces(RequestID, RequestStatus) {
  let data = { RequestID: RequestID, RequestStatus: RequestStatus};
   return await axios
     .post(EndpointUpdateTransactions, data, { headers: header})
     .then((response) => {
      toast.success("Request Closed!")
       return response.data.Response;
     })
     .catch((error) => {
       console.log(error);
     });
 }

export async function GetCustomerDetail(data) {
   return await axios
     .post(EndpointGetCustomerDetail, data, { headers: {'vlr-authorization': 'eyJpZCI6IldBQU1fQVBQIn0=', 'X-Api-Key': process.env.REACT_APP_API_KEY}})
     .then((response) => {
       return response.data.body;
     })
     .catch((error) => {
       console.log(error);
     });
 }
