import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

const EndpointInsetFile = process.env.REACT_APP_API_URL + "/File/Insert";

const EndpointGetFile = process.env.REACT_APP_AUTH_URL + "/user/picture";

const EndpointUpdate = process.env.REACT_APP_AUTH_URL + "/user/update-profile"


const folder = "profile"
const header = {
    'vlr-authorization': 'eyJpZCI6IldBQU1fQVBQIn0=',
    'authorization': Cookies.get("_ssoToken")
}
export async function sendFile(file, id) {
    const dataList = []
    await fileToBase64(file).then((FileBase64) => {
        FileBase64 = FileBase64.split(",").pop();
        if (FileBase64 !== "Error") {
            const data = {
                file: FileBase64,
                name: id + ".jpg",
                folder: folder,
            };
            dataList.push(data);
        }
    });
    return await axios
        .post(EndpointInsetFile, dataList, { headers: header })
        .then((response) => {
            toast.success("Image changed!")
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function GetProfileImage() {
    return await axios
        .post(EndpointGetFile, [], { headers: { 'authorization': Cookies.get("_ssoToken") } })
        .then((response) => {
            return response.data.body;
        })
        .catch((error) => {
            console.log(error);
        });
}

export async function UpdateUser(data) {
    return await axios
        .post(EndpointUpdate, data, { headers: { 'authorization': Cookies.get("_ssoToken") } })
        .then((response) => {
            toast.success(response.data.body.status)
            return response.data;
        })
        .catch((error) => {
            toast.success(error)
            console.log(error);
        });

}

const fileToBase64 = (file) => {
    return new Promise((resolve) => {
        var reader = new FileReader();

        reader.onload = function (event) {
            resolve(event.target.result);
        };
        reader.onerror = function (error) { };

        // Convert data to base64
        reader.readAsDataURL(file);
    });
}