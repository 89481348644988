import styled from 'styled-components'
import FlexView from './FlexView'

const OutlineButton = styled(FlexView)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 0px 4px #aaa;
  }
`

export default OutlineButton