import styled from 'styled-components'

const FlexView = styled.div`
  display: flex;
  ${({ flex }) => flex && `flex: ${flex};`}
  position: ${({ position }) => position || 'static'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent}) => justifyContent || 'flex-start'};
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  padding: ${({ padding }) => padding || '0px'};
  margin: ${({ margin }) => margin || '0px'};
  
  /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {display:none}
`

export default FlexView