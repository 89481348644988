import React, { useState } from "react";
import Closure from "../assets/images/TubeFormat/Collar.png";
import Collar from "../assets/images/TubeFormat/Collar.png";
import Flange from "../assets/images/TubeFormat/Flange.png";
import Gasket from "../assets/images/TubeFormat/Gasket.png";
import Tube from "../assets/images/TubeFormat/Tube.png";
import Xover from "../assets/images/TubeFormat/X-over.png";

const initialState = {
  item: 1,
};

export const PieceContext = React.createContext(initialState);

export const PieceProvider = ({ children }) => {
  const [pieceStore, setPieceStore] = useState(null);
  const [costumerDetail, setCostumerDetail] = useState([]);
  
  const ConvertMesurementsTable = (Mesurements, Metric) => {
    if (Array.isArray(Mesurements)) {
      let unit =
        pieceStore.GeometryInfo.MeasuringUnit === "Imperial" ? "inch" : "mm";
      let MesurementsList = [];

      Mesurements.forEach((mesure) => {
        MesurementsList.push(
          <tr key={Math.random()}>
            <th className="PieceImage">{mesure.Key}</th>
            <th className="PieceImageText">{mesure.Value + " " + unit}</th>
          </tr>
        );
      });
      return MesurementsList;
    }
    return <div />;
  };

  const ConvertImages = (Mesurements) => {
    if (Array.isArray(Mesurements)) {
      let MesurementsList = [];

      Mesurements.forEach((mesure) => {
        MesurementsList.push(
          <div key={Math.random()}>
            <a href={mesure}> {mesure.substring(71)} </a>{" "}
          </div>
        );
      });
      return MesurementsList;
    }
    return <div />;
  };

  const ReturnImages = (Image) => {
    switch (Image) {
      case "Closure":
        return <img src={Closure} className="FormatImage" alt="Closure" />;
      case "Collar":
        return <img src={Collar} className="FormatImage" alt="Collar" />;
      case "Flange":
        return <img src={Flange} className="FormatImage" alt="Flange" />;
      case "Gasket":
        return <img src={Gasket} className="FormatImage" alt="Gasket" />;
      case "Tube":
        return <img src={Tube} className="FormatImage" alt="Tube" />;
      case "Xover":
        return <img src={Xover} className="FormatImage" alt="Xover" />;
      default:
        return <div />;
    }
  };
  
  const renderPiece = (
    <PieceContext.Provider
      value={{
        pieceStore,
        setPieceStore,
        costumerDetail, 
        setCostumerDetail,
        ConvertMesurementsTable,
        ConvertImages,
        ReturnImages
      }}
    >
      {children}
    </PieceContext.Provider>
  );

  return renderPiece;
};
