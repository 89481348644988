import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiShapeSquare } from "react-icons/bi";
import { RiNewspaperLine, RiTestTubeLine } from "react-icons/ri";
import styled from "styled-components";
import { Button } from "../../components/common";
import { useHistory } from "react-router-dom";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { PieceContext } from "../../stores/PieceStore";
import * as piece from "../../components/connect/piece";
import { useReactToPrint } from "react-to-print";
import _ from 'lodash'
const Piece = () => {
  document.title = "WAAM App";
  const history = useHistory();
  const { 
    pieceStore, 
    costumerDetail, 
    ConvertMesurementsTable, 
    ConvertImages, 
    ReturnImages } = useContext(PieceContext);
  const { t } = useTranslation();

  const SideBySideOptions = styled.div`
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: top;
    width: 100%;
    position: relative;
  `;

  const CenterDiv = styled.div`
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: flex-start;
  `;

  const ProfileForm = styled.div`
    margin-top: 0;
    margin-left: 10px;
    font-size: calc(8px + 0.5vw);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 95%;
    color: black;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);

    .first {
      flex: 1;
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .second {
      flex: 1;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .FirstSmaller {
        flex: 1;
        margin-left: 25px;
        padding-right: 10px;
        display: flex;
        align-items: stretch;
        flex-direction: row;
      }
      .SecondSmaller {
        flex: 1;
        padding-right: 0px;
        display: flex;
        align-items: stretch;
      }
    }

    .PieceImage {
      border-right: 1px solid #dddddd;
      width: 50px;
    }

    .centerImage {
      display: flex;
      align-items: center;
    }

    .PieceImageText {
      text-align: left;
    }

    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      display: flex;
      flex-direction: column;
      border-radius: 0 0 20px 20px;
      margin-bottom: 20px;
    }

    .BorderTable {
      border-radius:20px;

      th{
        padding:15px;
      }
    }

    .FormatImage {
      padding: 10px;
      width: 100px;
      height: 100px;
      margin-left: 10px;
      border: 1px solid #dddddd;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    }

    .mainInfo {
      padding: 10px;
      text-align: center;
      font-weight: bold;
    }

    td,
    th {
      text-align: left;
      padding: 8px;
      font-weight: normal;
    }

    .GreyTable {
      width: 150px;
      background-color: #f6f6f6;
      border-right: 1px solid #dddddd;
    }

    .GreyMaterialTable {
      width: 150px;
      background-color: #f6f6f6;
      border-right: 1px solid #dddddd;
    }

    .GreyGeometryTable {
      border-right: 1px solid #dddddd;
      background-color: #f6f6f6;
      width: 150px;
    }

    .Card {
      max-width: 750px;
      border-radius: 10px;
      background-color: white;
      border: 1px solid #dddddd;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -25%);
      font-family: roboto, "helvetica neue", helvetica, arial, sans-serif;
      -webkit-text-size-adjust: 100%;
    }

    .Padding {
      padding: 10px;
    }

    .PaddingBot {
      padding-bottom: 10px;
    }

    .PaddingTop {
      padding-top: 10px;
    }

    .PaddingBotBig {
      padding-bottom: 30px;
    }

    .SortLeft {
      text-align: left;
    }

    .SortRequest {
      padding-left: 30px;
      border: 1px solid #dddddd;
      border-radius: 10px;
    }

    .Title {
      font-size: larger;
      padding: 20px;
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
    }

    .MaterialTitle {
      padding: 15px;
      text-align: center;
      border-radius: 10px 10px 0 0;
      border: 1px solid #dddddd;
      border-bottom: 0px;
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
    }

    .GeometryTitle {
      padding-top: 20px;
      padding-bottom: 10px;
      border-radius: 10px 10px 0 0;
      border: 1px solid #ddddddbe;
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
      border-bottom: 0px;
      text-align: center;
    }

    .BiggerDoubt {
      padding-top: 20px;
      font-size: smaller;
    }

    .SmallerText {
      font-size: smaller;
    }

    .ThankYou {
      font-size: smaller;
    }

    body {
      background-color: #f6f6f6;
      margin: 0px;
      padding: 0px;
    }
  `;

  const ButtonDiv = styled.div`
    display: flex;
    justify-content: center;
    button {
      min-height: 50px;
      min-width: 200px;
      margin-right:50px;
      background-color: white;
      color: #039fe0;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;


  const handleReturn = () => {
    history.goBack();
  };

  const handleEdit = () => {
    setTimeout(async () => {
      var update = await piece.UpdatePieces(pieceStore.RequestID, "Achieved");
      history.push("/ListPieces");
      return update;
    }, 50);
  };

  const ref = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  })

  return (
    <Body>
      <Header />
      <NavBar />
      <MainCard id="MainCard">
        <SideBySideOptions>
          <HelloTextBold>{t("Messages.PieceInformation")}</HelloTextBold>
          <ButtonDiv>
            <Button onClick={handleReturn}>
              {t("Messages.Return")}
            </Button>{" "}
            <Button onClick={handleEdit}>
              {t("Messages.CloseRequest")}
            </Button>{" "}
            <Button onClick={handlePrint}>
              {t("Messages.PrintAsPDF")}
            </Button>{" "}
          </ButtonDiv>
        </SideBySideOptions>
        <CenterDiv>
          <ProfileForm ref={ref}>
            <SideBySideOptions>
              <div className="first">
                <div className="MaterialTitle">
                  <RiNewspaperLine size="1.5em" />
                  <div /> {t("Messages.Request")}
                </div>
                <table>
                  <tr key={Math.random()}>
                    <th className="GreyTable">{t("Messages.RequestID")}</th>
                    <th>{pieceStore != null ? pieceStore.RequestID : ""}</th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyTable">{t("Messages.RequestFrom")}</th>
                    <th>{pieceStore != null ? _.map(costumerDetail, (i) => {return i.id === pieceStore.BuyerID ? (" " + i.firstName + " " + i.lastName) : " "}) : ""}</th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyTable">{t("Messages.Email")}</th>
                    <th>{pieceStore != null ? _.map(costumerDetail, (i) => {return i.id === pieceStore.BuyerID ? (" " + i.email) : " "}) : ""}</th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyTable">{t("Messages.Company")}</th>
                    <th>{pieceStore != null ? _.map(costumerDetail, (i) => {return i.id === pieceStore.BuyerID ? (" " + i.company) : " "}) : ""}</th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyTable">{t("Messages.CreationDate")}</th>
                    <th>{pieceStore != null ? pieceStore.CreationDate : ""}</th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyTable">{t("Messages.RequestStatus")}</th>
                    <th>
                      {pieceStore != null ? pieceStore.RequestStatus : ""}
                    </th>
                  </tr>
                </table>
                <div className="MaterialTitle">
                  <RiTestTubeLine size="1.5em" />
                  <div />
                  {t("Messages.Material")}
                </div>

                <table>
                  <tr key={Math.random()}>
                    <th className="GreyMaterialTable">{t("Messages.MaterialType")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.MaterialInfo.MaterialType
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyMaterialTable">{t("Messages.MaterialName")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.MaterialInfo.MaterialName
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyMaterialTable">{t("Messages.CrContent")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.MaterialInfo.CrContent
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyMaterialTable">{t("Messages.YeldStrengthMin")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.MaterialInfo.YeldStrengthMin +
                        " " +
                        (pieceStore.MaterialInfo.IsKsi === true
                          ? "Ksi"
                          : "Mpa")
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyMaterialTable">{t("Messages.YeldStrengthMax")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.MaterialInfo.YeldStrengthMax +
                        " " +
                        (pieceStore.MaterialInfo.IsKsi === true
                          ? "Ksi"
                          : "Mpa")
                        : ""}
                    </th>
                  </tr>
                </table>
              </div>

              <div className="second">
                <div className="MaterialTitle">
                  <BiShapeSquare size="1.5em" /> <div /> {t("Messages.Geometry")}
                </div>

                <table>
                  <tr key={Math.random()}>
                    <th className="GreyGeometryTable">{t("Messages.MesuringUnit")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.GeometryInfo.MeasuringUnit
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyGeometryTable">{t("Messages.GeometryType")}</th>
                    <th>
                      {pieceStore != null
                        ? ReturnImages(pieceStore.GeometryInfo.GeometryType)
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyGeometryTable">{t("Messages.Mesurements")}</th>
                    <th>
                      <table className="BorderTable">
                        <tbody>
                          {pieceStore != null
                            ? ConvertMesurementsTable(
                              pieceStore.GeometryInfo.Measurements,
                              pieceStore.GeometryInfo.MeasuringUnit
                            )
                            : ""}
                        </tbody>
                      </table>
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyGeometryTable">{t("Messages.StepFile")}</th>
                    <th>
                      {pieceStore != null ? (
                        <a href={pieceStore.GeometryInfo.StepFileLink}>
                          {" "}
                          {pieceStore.GeometryInfo.StepFileLink.substring(
                            71
                          )}{" "}
                        </a>
                      ) : (
                        ""
                      )}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyGeometryTable">{t("Messages.ImagesUploaded")}</th>
                    <th>
                      {pieceStore != null
                        ? ConvertImages(
                          pieceStore.GeometryInfo.ImgsUploadedLink
                        )
                        : ""}
                    </th>
                  </tr>
                  <tr key={Math.random()}>
                    <th className="GreyGeometryTable">{t("Messages.ExtraComments")}</th>
                    <th>
                      {pieceStore != null
                        ? pieceStore.GeometryInfo.ExtraComments
                        : ""}
                    </th>
                  </tr>
                </table>
              </div>
            </SideBySideOptions>
          </ProfileForm>
        </CenterDiv>
      </MainCard>
      {/* <Footer /> */}
    </Body>
  );
};

export default Piece;
