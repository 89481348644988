import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineOrderedList, AiOutlineRight } from "react-icons/ai";
import { BiShapeSquare } from "react-icons/bi";
import { BsListCheck } from "react-icons/bs";
import { RiTestTubeLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AssemblyMakeUp from "../../assets/images/LandingPage/AssemblyMakeUp.png";
import { Button } from "../../components/common";
import {
  Body,
  HelloText,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import * as piece from "../../components/connect/piece";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { UserContext } from "../../stores/UserStore";
import { LandingPageContext } from "../../stores/LandingPageStore";

const LandingPage = () => {
  document.title = "WAAM App";

  const history = useHistory();
  const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();

  
  const handleListPiece = () => {
    history.push("/ListPieces");
};

  const { 
    gotCustomerInformation,
    submittedPiecesAmount,
    frequentMaterialPieces,
    frequentTypePieces,
    amountPending,
    CountPieces,
    setGotCustomerInformation
  } = useContext(LandingPageContext);

  const SideBySideDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 25px;
    button {
      min-height: 50px;
      width: 300px;
      background-color: #009ee0;
      color: white;
      margin-top: 20px;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const SideBySideOption = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 25px;
    margin-left: 30px;
  `;

  const LongCard = styled.div`
    color: gray;
    background-color: white;
    width: 100%;
    height: 250px;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    margin-top: 20px;
    position: relative;

    .Title {
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
    }

    .JobList {
      padding-left: 75px;
      padding-right: 75px;
      display: flex;
      justify-content: space-between;
    }

    .MiniCard {
      width: 23%;
      margin-left: 10px;
      margin-bottom: 10px;
      height: 215px;
      justify-content: center;
      text-align: center;
      .Title {
        margin-top: 20px;
        margin-bottom: 10px;
        color: black;
      }
    }
    img {
      padding-top: 20px;
      padding-bottom: 10px;
      width: 50px;
      height: 50px;
    }

    .ButtonListOnSiteSupport {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      margin: 0px;
    }
  `;

  const Card = styled.div`
    color: #009ee0;
    background-color: white;
    width: 33%;
    height: 200px;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    position: relative;
    & + div {
      margin-left: 4vw;
    }

    img {
      height: 55px;
      padding-top: 40px;
    }

    button {
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      justify-content: space-between;
      width: 90%;
      background-color: #fff;
      color: #009ee0;
      margin-bottom: 20px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        color: #fff;
        transform: translateX(7px);
      }
    }

    .Title {
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
    }

    .BottomOfCard {
      position: absolute;
      bottom: 10px;
      width: 100%;
      .Subtitle {
        font-size: 12px;
        text-align: left;
        color: gray;
        margin-left: 20px;
      }
    }
  `;

  const GetCustomerData = function() {
    if (gotCustomerInformation === false) {
        const today = new Date();
        var startMonth = new Date();

        startMonth.setDate(1);

        setTimeout(async () => {
            var result = await piece.ListAllPieces(startMonth, today);
            if (result !== undefined && result !== []) {
                CountPieces(result);
            }
            setGotCustomerInformation(true);
        }, 50);
    }
};


  console.log(gotCustomerInformation)
  return (
    <Body>
      {GetCustomerData()}
      <Header />
      <NavBar />
      <span style={{ display: !gotCustomerInformation ? "block" : "none" }}>
        <LoadingScreen />
      </span>
      <span style={{ display: gotCustomerInformation ? "block" : "none" }}>
        <MainCard id="MainCard">
          <SideBySideDiv>
            <HelloTextBold>
              {t("LandingPage.Hello")}{" "}
              {currentUser != null && currentUser.firstName}!
            </HelloTextBold>
          </SideBySideDiv>
          <HelloText> {t("LandingPage.Whatcanwedoforyou?")}</HelloText>

          <SideBySideOption>
            <Card>
              <img alt="" src={AssemblyMakeUp} />
              <div className="BottomOfCard">
                <Button onClick={handleListPiece}>
                  <div>{t("LandingPage.SeeAllPartsRequests")}</div>
                  <AiOutlineRight size="1em" />
                </Button>
              </div>
            </Card>
          </SideBySideOption>
          <SideBySideOption>
            <LongCard>
              <div className="Title">{t("LandingPage.Informations")}</div>
              <SideBySideOption>
                <div className="MiniCard">
                  <AiOutlineOrderedList
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">
                    {t("LandingPage.SubmittedPieces")}{" "}
                  </div>
                  {submittedPiecesAmount}
                </div>
                <div className="MiniCard">
                  <BsListCheck
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">{t("LandingPage.AmountPending")}</div>
                  {amountPending}
                </div>
                <div className="MiniCard">
                  <RiTestTubeLine
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">
                    {t("LandingPage.MostDesiredMaterial")}
                  </div>
                  {frequentMaterialPieces}
                </div>
                <div className="MiniCard">
                  <BiShapeSquare
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">
                    {t("LandingPage.MostDesiredFormat")}
                  </div>
                  {frequentTypePieces}
                </div>
              </SideBySideOption>
            </LongCard>
          </SideBySideOption>
        </MainCard>
      </span>
      {/* <Footer /> */}
    </Body>
  );
};

export default LandingPage;
