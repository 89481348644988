import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/images/BlueLogo.png";
import { UserContext } from "../../stores/UserStore";
import { GetProfileImage } from "../../components/connect/profile";

const Header = (props) => {
  const { signOut, currentUser } = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();
  var ProfilePicture = "";

  const redirectIfNotAuthenticated = () => {
    if (currentUser && (currentUser.isMaintainerUser === false || currentUser.isSuperAdmin === false)) {
      history.push("/access-denied");
    }
  }

  const handleProfile = () => {
    history.push("/Profile");
  };

  const handleSignOut = () => {
    signOut();
  };

  const Header = styled.div`
    font-family: Roboto;
    float: top;
    width: 100vw;
    height: 90px;
    position: relative;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(167, 164, 206, 0.4);
  `;

  const Logo = styled.img`
    height: 32px;
    width: 127px;
    margin-left: 3vh;
    padding-top: 30px;
    display: block;
  `;

  const ProfileOptions = styled.div`
    display: none;
    position: absolute;
    right: 1em;
    background-color: #f1f1f1;
    min-width: 100px;
    text-align: center;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 120px;
    }
    ul li {
      position: relative;
      background-color: #f1f1f1;
    }
    li ul {
      position: absolute;
      right: 155px;
      top: -10px;
      background-color: #f1f1f1;
      display: none;
    }

    ul li button {
      color: black;
      padding: 12px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      width: 100%;
      border: 0;
      :hover {
        background-color: #ddd;
        text-decoration: none;
      }
    }
    li:hover ul {
      display: block;
    }
  `;

  const Profile = styled.div`
    position: absolute;
    right: 2vh;
    top: 30px;

    &:hover ${ProfileOptions} {
      display: block;
    }
  `;

  const ProfileButton = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `;

  const getProfilePic = () => {
    GetProfileImage(currentUser.id).then((response) => {
      ProfilePicture = response
    })
  };
  

  return (
    <Header>
      {redirectIfNotAuthenticated()}
      {getProfilePic()}
      <Logo src={logo} />
      <Profile>
        <ProfileButton>
          {ProfilePicture === null || ProfilePicture === undefined || ProfilePicture === "" ? (
            <CgProfile size="25px" style={{ marginRight: "5px" }} />
          ) : (
            <img
              src={ProfilePicture}
              alt="Profile"
              style={{ width: "25px", marginRight: "5px", borderRadius: "50%" }}
            />
          )}
          <font style={{ fontWeight: "bold" }}>
            {currentUser != null &&
              currentUser.firstName + " " + currentUser.lastName.split("")[0]}
            .
          </font>
        </ProfileButton>
        <ProfileOptions>
          <ul id="nav">
            <li>
              <button onClick={() => handleProfile()}>{t("Header.Profile")}</button>
            </li>
            <li>
              <button onClick={handleSignOut}>{t("Header.SignOut")}</button>
            </li>
          </ul>
        </ProfileOptions>
      </Profile>
    </Header>
  );
};

export default Header;
